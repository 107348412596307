<template>
  <div>
    <h1>Dashboard Admin</h1>
  </div>
</template>
<script>
export default {
  name: "DashboardAdmin",
};
</script>
